var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inputBoxContent commentInputBox_old" }, [
    _vm.showAvatar
      ? _c(
          "div",
          { staticClass: "avatarBox" },
          [
            _c("Avatar", {
              attrs: {
                path: _vm.avatarPath,
                providerId: _vm.$store.getters.userInfo.id,
                international:
                  _vm.$store.getters.userInfo.country !== ""
                    ? Number(_vm.$store.getters.userInfo.country)
                    : 0
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "inputBoxOuter" }, [
      _c(
        "div",
        {
          staticClass: "inputBox",
          class: _vm.getFocus ? "getFocusBorderStyle" : "",
          style: {
            minHeight: _vm.largeInput ? "60px" : "40px",
            alignItems: _vm.largeInput ? "flex-start" : "center"
          }
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.answerSomeone != ""
              ? _c("div", { staticClass: "answerSomeoneBlock" }, [
                  _c("span", {
                    staticClass: "iconfont_Me icon-reply1 iconStyle"
                  }),
                  _c("div", { staticClass: "answerSomeName" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.answerSomeone) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _c("el-input", {
            staticClass: "inputStyle",
            attrs: {
              type: "textarea",
              resize: "none",
              autosize: { minRows: 1, maxRows: _vm.autoHeight ? 5 : 1 },
              maxlength: "200",
              placeholder: _vm.placeholderIcon
            },
            on: { focus: _vm.inputFocusFn },
            nativeOn: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                    "Backspace",
                    "Delete",
                    "Del"
                  ])
                )
                  return null
                return _vm.deleteMonitor.apply(null, arguments)
              }
            },
            model: {
              value: _vm.commentText,
              callback: function($$v) {
                _vm.commentText = $$v
              },
              expression: "commentText"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "submitButton",
          class: _vm.getFocus ? "getFocusStyle" : "",
          style: {
            width: _vm.largeInput ? "60px" : "40px",
            height: _vm.largeInput ? "60px" : "40px"
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.triggerSubmit.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }