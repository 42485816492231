<template>
  <div class="commentBlock">
    <div class="avatarBlock">
      <Avatar
        :providerId="comment.reviewerId"
        :international="
          comment.reviewerCountry && comment.reviewerCountry !== ''
            ? Number(comment.reviewerCountry)
            : 0
        "
        :path="comment.userAvatar"
        :popover="true"
      >
      </Avatar>
    </div>
    <div class="commentInfo">
      <!-- {{comment}} -->
      <div
        class="commentAuthor"
        @click.stop="clickThisUser"
        style="display:flex;align-items: center;"
      >
        <span :class="isVip ? 'vipName' : ''">
          {{ comment.reviewer }}
        </span>
        <el-image
          v-if="isVip"
          style="width: 17px; height: 14px;"
          :src="require('../../../assets/images/pay/masonry.png')"
          fit="fill"
        >
        </el-image>
      </div>
      <div
        class="commentContent"
        v-html="$emoReplaceToImage(hyperlinksCheck(commentContent))"
      ></div>
      <CommentFooter
        style="marginBottom: 15px"
        :comment="comment"
        @replyToThisPerson="replyToThisPerson"
        :type="getCommentsParam.type"
      ></CommentFooter>
      <div class="viceCommentBox" v-if="comment.children.length != 0">
        <transition-group name="list" tag="div">
          <div
            class="viceCommentItem"
            v-for="(item, index) in comment.children"
            :key="item.reviewId"
          >
            <ViceComment
              :viceComment="item"
              @replyToThisPerson="replyToThisPerson"
              :type="getCommentsParam.type"
            >
            </ViceComment>
          </div>
        </transition-group>
        <div class="moreComment" v-if="needShowMoreComment">
          <span @click.stop="showMoreComment"
            >共{{ comment.childrenCount }}回复</span
          >
          <span
            @click.stop="showMoreComment"
            class="iconfont_Me iconStyle"
            :class="getCommentsParam.popupDisplay ? 'icon-down' : 'icon-right'"
          ></span>
        </div>
      </div>
      <transition name="fade">
        <CommentInputBox
          v-if="displayTheInputBox"
          class="commentInputBox"
          :answerSomeone="answerSomeone"
          :showAvatar="showAvatar"
          @deleteAnswerSomeone="deleteAnswerSomeone"
          @triggerSubmit="triggerSubmit"
          :largeInput="true"
        ></CommentInputBox>
      </transition>
      <div class="intervalLine"></div>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
import ViceComment from "./viceComment";
import CommentInputBox from "./commentInputBox_old";
import CommentFooter from "./commentFooter";
import { addNewReview } from "@/api/newVersion";
export default {
  components: {
    Avatar,
    ViceComment,
    CommentInputBox,
    CommentFooter,
  },
  props: {
    // 主评论内容
    comment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否可以在评论里面回复
    replyInTheComponent: {
      type: Boolean,
      default: false,
    },
    // 评论列表参数
    getCommentsParam: {
      type: Object,
      default: () => {
        return {
          // 点赞类型(1:service(服务)，2:jobs(需求),3:people(个人)，4:group(群组), 5:enterprise(企业))
          type: 0,
          // 是否是用弹窗查看更多评论
          popupDisplay: false,
        };
      },
    },
    // 评论输入框是否展示自己的头像
    showAvatar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVip: false,
      // 默认关闭输入框
      showTheInputBox: false,
      // 要回复的人的信息
      answerSomeone: "",
      // 正在回复的评论
      inReplyComment: null,
    };
  },
  computed: {
    // 是否可以展示输入框(需要添加当前是否只展示了这一个输入框的判断，后面来)
    displayTheInputBox() {
      return this.replyInTheComponent && this.showTheInputBox;
    },
    // 处理需要展示的评论内容
    commentContent() {
      return this.reconvert(this.comment.content);
    },
    // 是否需要展示更多评论
    needShowMoreComment() {
      return this.comment.childrenCount > this.comment.children.length;
    },
  },
  methods: {
    // 回复评论触发
    async triggerSubmit(commentText) {
      let params = {
        operation: this.getCommentsParam.operation,
        reply: commentText,
        reviewId: this.inReplyComment.reviewId,
      };
      // 关闭输入框
      this.deleteAnswerSomeone();
      let result = await addNewReview(this.getCommentsParam.id, params);
      if (result.code === 200) {
        this.$store.commit("setNeedRefreshComment", this.comment.reviewId);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 点击查看更多评论消息
    showMoreComment() {
      // 将评论设置的参数带到弹窗里去
      let comment = _.cloneDeep(this.comment);
      comment.getCommentsParam = this.getCommentsParam;
      if (this.getCommentsParam.popupDisplay) {
        // 打开更多弹窗
        this.$store.dispatch(
          "commitOpenMoreCommentPopup",
          _.cloneDeep(comment)
        );
      } else {
        // 打开更多滑窗
        this.$emit("openSlidingWindow", comment);
      }
    },
    // 点击回复这个用户
    replyToThisPerson(comment) {
      // 如果没有开启在评论中打开输入框，则将事件回传到最外层组件
      if (!this.replyInTheComponent) {
        // 将评论设置的参数带到外层组件去
        let handleComment = _.cloneDeep(comment);
        handleComment.getCommentsParam = this.getCommentsParam;
        this.$emit("replyToThisComment", handleComment);
      } else {
        this.showTheInputBox = true;
        this.$nextTick(() => {
          this.inReplyComment = comment;
          this.answerSomeone = comment.reviewer;
        });
      }
    },
    // 将Unicode转化为评论
    reconvert(str) {
      str = str.replace(/(\\u)(\w{1,4})/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#x)(\w{1,4});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2"))
        );
      });
      return str;
    },
    // 将评论转化为Unicode
    ascii(str) {
      let value = "";
      for (var i = 0; i < str.length; i++) {
        value +=
          "\&#x" + left_zero_4(parseInt(str.charCodeAt(i)).toString(16)) + ";";
      }
      return value;
    },
    // 删除要回复的人
    deleteAnswerSomeone() {
      this.showTheInputBox = false;
      this.$nextTick(() => {
        this.inReplyComment = null;
        this.answerSomeone = "";
      });
    },
    // 点击用户名字是否跳转
    clickThisUser() {
      console.log("点了这个用户");
    },
  },
};
</script>
<style lang="stylus" scoped>
.list-enter-active, .list-leave-active {
  transition: opacity .3s, transform .3s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform translate3d(30px,0,0);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s, transform .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform translate3d(0,30px,0);
}
.commentBlock
  width 100%;
  min-height 70px;
  display flex;
  align-items flex-start;
  justify-content flex-start;
  margin-top 20px;
  .avatarBlock
    height 40px;
    width 40px;
    flex-shrink 0;
    overflow hidden;
  .commentInfo
    min-height 70px;
    flex 1;
    min-width 0;
    overflow hidden;
    box-sizing border-box;
    display flex;
    flex-flow column;
    align-items center;
    justify-content flex-start;
    padding-left 13px;
    .commentAuthor
      width 100%;
      height 20px;
      color #373737;
      cursor pointer;
      font-size 14px;
      font-weight bold;
      text-align left;
      line-height 20px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    .commentContent
      width 100%;
      margin-top 5px;
      min-height 20px;
      color #333333;
      font-size 14px;
      line-height 20px;
      word-break:break-all;
    .viceCommentBox
      width 100%;
      overflow hidden;
      .viceCommentItem
        height auto;
        width 100%;
      .moreComment
        color #373737;
        font-size 14px;
        line-height 24px;
        text-align left;
        span
          cursor pointer;
        .iconStyle
          margin-left 7px;
          color #333333
          font-size 14px;
          line-height 24px;
    .commentInputBox
      margin 15px 0;
    .intervalLine
      width 100%;
      height 1px;
      background #F0F0F0;
</style>
