var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listAreaContent" },
    _vm._l(_vm.likeProviders, function(item) {
      return _c(
        "div",
        { key: item.providerId, staticClass: "likeAvatarItem" },
        [
          _c("Avatar", {
            attrs: {
              providerId: item.providerId,
              path: item.userAvatar,
              international: item.country !== "" ? Number(item.country) : 0,
              popover: true
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }