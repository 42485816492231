<template>
  <div class="listAreaContent">
    <div class="likeAvatarItem" v-for="item in likeProviders" :key="item.providerId">
      <Avatar :providerId="item.providerId" :path="item.userAvatar" :international="item.country!==''?Number(item.country):0" :popover="true"></Avatar>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
export default {
  components: {
    Avatar
  },
  props: {
    likeProviders: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    likeProviders: {
      handler(val) {
        // console.log(val)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
  .listAreaContent
    width 100%;
    min-height 200px;
    overflow hidden;
    box-sizing border-box;
    padding 15px 0 15px 3px;
    .likeAvatarItem
      width 40px;
      height 40px;
      display inline-block;
      margin 0 6px 8px 0;

</style>