<template>
  <div class="inputBoxContent commentInputBox_old">
    <div class="avatarBox" v-if="showAvatar">
      <Avatar
        :path="avatarPath"
        :providerId="$store.getters.userInfo.id"
        :international="
          $store.getters.userInfo.country !== ''
            ? Number($store.getters.userInfo.country)
            : 0
        "
      ></Avatar>
    </div>
    <div class="inputBoxOuter">
      <div
        class="inputBox"
        :class="getFocus ? 'getFocusBorderStyle' : ''"
        :style="{
          minHeight: largeInput ? '60px' : '40px',
          alignItems: largeInput ? 'flex-start' : 'center',
        }"
      >
        <transition name="fade">
          <div class="answerSomeoneBlock" v-if="answerSomeone != ''">
            <span class="iconfont_Me icon-reply1 iconStyle"></span>
            <div class="answerSomeName">
              {{ answerSomeone }}
            </div>
          </div>
        </transition>
        <el-input
          class="inputStyle"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 1, maxRows: autoHeight ? 5 : 1 }"
          v-model="commentText"
          maxlength="200"
          :placeholder="placeholderIcon"
          @keydown.delete.native="deleteMonitor"
          @focus="inputFocusFn"
        ></el-input>
      </div>
      <div
        class="submitButton"
        :class="getFocus ? 'getFocusStyle' : ''"
        :style="{
          width: largeInput ? '60px' : '40px',
          height: largeInput ? '60px' : '40px',
        }"
        @click.stop="triggerSubmit"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
export default {
  components: {
    Avatar,
  },
  props: {
    // 是否展示用户头像
    showAvatar: {
      type: Boolean,
      default: false,
    },
    // 回复的人的姓名
    answerSomeone: {
      type: String,
      default: "",
    },
    // 输入框高度根据内容自动变高
    autoHeight: {
      type: Boolean,
      default: true,
    },
    // 大号输入框
    largeInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 获取到焦点
      getFocus: false,
      // 输入内容
      commentText: "",
      // 默认文本
      placeholderIcon: "\ue6a1",
    };
  },
  watch: {
    // 监听输入
    commentText: {
      handler(val) {
        if (val && this.globalTrim(val) != "") {
          this.getFocus = true;
        } else {
          this.getFocus = false;
        }
      },
      deep: true,
      immediate: true,
    },
    // 回复人的姓名变化的时候，清空输入框内的信息
    answerSomeone: {
      handler(val, old) {
        if (val && val != "" && val != old) {
          this.commentText = "";
        }
      },
      deep: true,
    },
  },
  computed: {
    // 用户的本地头像
    avatarPath() {
      return this.$store.getters.userInfo.avatar;
    },
  },
  methods: {
    // 获取焦点触发监听
    inputFocusFn() {
      this.$emit("commentInputFocus");
    },
    // 删除键监听
    deleteMonitor() {
      if (this.commentText == "" && this.answerSomeone != "") {
        this.$emit("deleteAnswerSomeone");
      }
    },
    // 触发提交评论事件
    triggerSubmit() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      if (this.getFocus && this.globalTrim(this.commentText) !== "") {
        this.$emit("triggerSubmit", this.commentText);
        this.$nextTick(() => {
          this.commentText = "";
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s, transform .1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform translate3d(-50px,0px,0);
}
.inputBoxContent
  width 100%;
  min-height 40px;
  overflow hidden;
  display flex;
  align-items flex-start;
  justify-content flex-start;
  .avatarBox
    width 40px;
    height 40px;
    flex-shrink 0;
    margin-right 14px;
  .inputBoxOuter
    flex 1;
    min-width 0;
    display flex;
    align-items flex-end;
    justify-content flex-end;
    .getFocusBorderStyle
      border 1px solid #33CC66 !important;
    .getFocusStyle
      cursor pointer !important;
      background #33CC66 !important;
    .inputBox
      flex 1;
      min-width 0;
      min-height 60px;
      box-sizing border-box;
      border-radius 4px;
      border 1px solid #CCCCCC;
      display flex;
      justify-content center;
      .answerSomeoneBlock
        height 100%;
        padding 3px 0 0 8px;
        flex-shrink 0;
        max-width 150px;
        display flex;
        color #33CC66;
        font-size 14px;
        align-items center;
        .iconStyle
          color #33CC66;
          font-size 20px;
        .answerSomeName
          overflow hidden;
          white-space: nowrap;
          box-sizing border-box;
          text-overflow:ellipsis;
      .inputStyle
        flex 1;
        min-width 0;
    .submitButton
      width 40px;
      height 40px;
      cursor not-allowed;
      flex-shrink 0;
      margin-left 6px;
      border-radius 4px;
      background #CCCCCC;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        color #FFFFFF;
        font-size 20px;
</style>
<style lang="stylus">
.commentInputBox_old
   .el-textarea
     .el-textarea__inner
       border none !important;
       font-family 'iconfont_Me';
       caret-color #33CC66;
       padding 5px 10px 1px 5px !important;
       font-size 14px !important;
       color #333333 !important;
       &::-webkit-scrollbar
         width 4px;
       &::-webkit-scrollbar-thumb
         border-radius: 2px;
         box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
         background: rgba(204,204,204,1);
       &::-webkit-scrollbar-track
         box-shadow: inset 0 0 5px rgba(0,0,0,0);
         border-radius: 0;
         background: rgba(240,240,240,0);
</style>
