<template>
  <div class="listContent">
    <transition-group name="list" tag="div">
      <div
        class="commentBox"
        v-for="(item, index) in comments"
        :key="item.reviewId"
      >
        <NewCommentBlock
          v-if="showNewComment"
          :comment="item"
          :popover="false"
          :showInput="true"
          :showBorder="true"
          :getCommentsParam="getCommentsParam"
          :openCommentInput="index == 0 && openCommentInput ? true : false"
        ></NewCommentBlock>
        <Comment
          v-else
          :comment="item"
          :replyInTheComponent="replyInTheComponent"
          @replyToThisComment="replyToThisComment"
          :getCommentsParam="getCommentsParam"
          @openSlidingWindow="openSlidingWindow"
          :showAvatar="true"
        ></Comment>
      </div>
    </transition-group>
    <div class="responEmptyStyle" v-if="showNoComments">
      {{ $t("responEmpty") }}
    </div>
  </div>
</template>
<script>
import NewCommentBlock from "../comment/newComment.vue";
import Comment from "../comment/mainComment";
import { getFatherComments } from "@/api/newVersion/postContentDetails";
export default {
  components: {
    NewCommentBlock,
    Comment,
  },
  props: {
    // 是否开启在评论内回复的功能
    replyInTheComponent: {
      type: Boolean,
      default: false,
    },
    // 获取评论列表数据的参数
    getCommentsParam: {
      type: Object,
      default: () => {
        return {
          // 操作对象ID
          id: "",
          // 操作对象(1:旧版帖子评论,3:用户,4:群,5:公司,7:新版帖子评论,8：新版个人中心评论)
          operation: 7,
          // 点赞用的type(1:帖子,3:用户,4:群)
          type: 1,
          // 这个参数表示更多评论是否用弹窗展示
          popupDisplay: true,
        };
      },
    },
    showNewComment: {
      type: Boolean,
      default: false,
    },
    openCommentInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 正在加载中,禁止再触发
      loadDisabled: false,
      // 评论列表
      comments: [],
      // 当前评论列表最后一条评论的ID，用于请求下一页
      lastReviewId: "",
      // 展示没有评论
      showNoComments: false,
    };
  },
  watch: {
    // 监听获取评论列表的ID
    "getCommentsParam.id": {
      async handler(val, old) {
        if (val && val != old) {
          this.comments = [];
          this.lastReviewId = "";
          await this.getReplaysList();
          this.$store.commit("setNeedRefreshOperationComment", "");
          this.$store.commit("setNeedRefreshComment", "");
        }
      },
      deep: true,
      immediate: true,
    },
    // 判断需要刷新的评论是不是属于当前列表(根据评论ID判断是否需要刷新列表)
    "$store.state.globalShare.needRefreshComment": {
      async handler(val, old) {
        // 判断更多评论弹窗是否是对应的当前这条评论,判断是否是关闭
        if (val && val !== old) {
          if (this.comments.some((e) => e.reviewId === val)) {
            this.comments = [];
            this.lastReviewId = "";
            await this.getReplaysList();
            this.$store.commit("setNeedRefreshComment", "");
          }
        }
      },
      deep: true,
    },
    // 判断需要刷新的操作对象是不是当前列表(根据操作对象ID（例如：帖子，群组）判断是否需要刷新列表)
    "$store.state.globalShare.needRefreshOperationComment": {
      async handler(val, old) {
        // 判断更多评论弹窗是否是对应的当前这条评论,判断是否是关闭
        if (val && val !== old) {
          if (val === this.getCommentsParam.id) {
            this.comments = [];
            this.lastReviewId = "";
            await this.getReplaysList();
            this.$store.commit("setNeedRefreshOperationComment", "");
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // 获取帖子评论列表
    getReplaysList() {
      return new Promise(async (resolve, reject) => {
        let params = {
          operation: this.getCommentsParam.operation,
          childrenSize: 3,
          lastReviewId: this.lastReviewId,
          showSize: 10,
        };
        let result = await getFatherComments(this.getCommentsParam.id, params);
        if (result.code === 200) {
          if (result.data.data.length !== 0) {
            this.comments = this.comments.concat(result.data.data);
            if (this.comments.length !== 0) {
              this.lastReviewId = this.comments[
                this.comments.length - 1
              ].reviewId;
            }
            if (result.data.data.length < 10) {
              this.showNoComments = true;
            }
          } else {
            this.showNoComments = true;
          }
          this.$emit("getCommentCount", result.data.total);
          this.$emit("getCommentsLength", this.comments.length);
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
    // 收到回复事件
    replyToThisComment(comment) {
      this.$emit("replyMessageReceived", comment);
    },
    openSlidingWindow(comment) {
      this.$emit("openSlidingWindow", comment);
    },
  },
};
</script>
<style lang="stylus" scoped>
.list-enter-active, .list-leave-active {
  transition: opacity .3s;
}
.list-enter, .list-leave-to {
  opacity: 0;
}
.listContent
  height auto;
  width 100%;
  .commentBox
    width 100%;
    overflow hidden;
  .responEmptyStyle
    width 100%;
    padding 24px;
    text-align center;
    color #CCCCCC;
    font-size 12px;
</style>
