var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listContent" },
    [
      _c(
        "transition-group",
        { attrs: { name: "list", tag: "div" } },
        _vm._l(_vm.comments, function(item, index) {
          return _c(
            "div",
            { key: item.reviewId, staticClass: "commentBox" },
            [
              _vm.showNewComment
                ? _c("NewCommentBlock", {
                    attrs: {
                      comment: item,
                      popover: false,
                      showInput: true,
                      showBorder: true,
                      getCommentsParam: _vm.getCommentsParam,
                      openCommentInput:
                        index == 0 && _vm.openCommentInput ? true : false
                    }
                  })
                : _c("Comment", {
                    attrs: {
                      comment: item,
                      replyInTheComponent: _vm.replyInTheComponent,
                      getCommentsParam: _vm.getCommentsParam,
                      showAvatar: true
                    },
                    on: {
                      replyToThisComment: _vm.replyToThisComment,
                      openSlidingWindow: _vm.openSlidingWindow
                    }
                  })
            ],
            1
          )
        }),
        0
      ),
      _vm.showNoComments
        ? _c("div", { staticClass: "responEmptyStyle" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("responEmpty")) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }