var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "commentBlock" }, [
    _c(
      "div",
      { staticClass: "avatarBlock" },
      [
        _c("Avatar", {
          attrs: {
            providerId: _vm.comment.reviewerId,
            international:
              _vm.comment.reviewerCountry && _vm.comment.reviewerCountry !== ""
                ? Number(_vm.comment.reviewerCountry)
                : 0,
            path: _vm.comment.userAvatar,
            popover: true
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "commentInfo" },
      [
        _c(
          "div",
          {
            staticClass: "commentAuthor",
            staticStyle: { display: "flex", "align-items": "center" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.clickThisUser.apply(null, arguments)
              }
            }
          },
          [
            _c("span", { class: _vm.isVip ? "vipName" : "" }, [
              _vm._v("\n        " + _vm._s(_vm.comment.reviewer) + "\n      ")
            ]),
            _vm.isVip
              ? _c("el-image", {
                  staticStyle: { width: "17px", height: "14px" },
                  attrs: {
                    src: require("../../../assets/images/pay/masonry.png"),
                    fit: "fill"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", {
          staticClass: "commentContent",
          domProps: {
            innerHTML: _vm._s(
              _vm.$emoReplaceToImage(_vm.hyperlinksCheck(_vm.commentContent))
            )
          }
        }),
        _c("CommentFooter", {
          staticStyle: { marginBottom: "15px" },
          attrs: { comment: _vm.comment, type: _vm.getCommentsParam.type },
          on: { replyToThisPerson: _vm.replyToThisPerson }
        }),
        _vm.comment.children.length != 0
          ? _c(
              "div",
              { staticClass: "viceCommentBox" },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "list", tag: "div" } },
                  _vm._l(_vm.comment.children, function(item, index) {
                    return _c(
                      "div",
                      { key: item.reviewId, staticClass: "viceCommentItem" },
                      [
                        _c("ViceComment", {
                          attrs: {
                            viceComment: item,
                            type: _vm.getCommentsParam.type
                          },
                          on: { replyToThisPerson: _vm.replyToThisPerson }
                        })
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.needShowMoreComment
                  ? _c("div", { staticClass: "moreComment" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.showMoreComment.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "共" + _vm._s(_vm.comment.childrenCount) + "回复"
                          )
                        ]
                      ),
                      _c("span", {
                        staticClass: "iconfont_Me iconStyle",
                        class: _vm.getCommentsParam.popupDisplay
                          ? "icon-down"
                          : "icon-right",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.showMoreComment.apply(null, arguments)
                          }
                        }
                      })
                    ])
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [
            _vm.displayTheInputBox
              ? _c("CommentInputBox", {
                  staticClass: "commentInputBox",
                  attrs: {
                    answerSomeone: _vm.answerSomeone,
                    showAvatar: _vm.showAvatar,
                    largeInput: true
                  },
                  on: {
                    deleteAnswerSomeone: _vm.deleteAnswerSomeone,
                    triggerSubmit: _vm.triggerSubmit
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "intervalLine" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }